import supportedIdVerificationService from '@/core/services/SupportedIdVerificationService';
import type SupportedIdVerification from '@/core/types/SupportedIdVerification';

export default function useSupportedIdVerification() {
  const supportedIds = ref<SupportedIdVerification[]>([]);
  const fetching = ref(false);
  const updating = ref(false);

  const { showSnackbar } = useSnackbar();

  const getSupportedIdVerifications = async (): SupportedIdVerification[] => {
    if (fetching.value) return;

    supportedIds.value = [];
    fetching.value = true;

    const { data } = await supportedIdVerificationService.getSupportedIdVerifications();

    supportedIds.value = parseSupportedIdObject(data.value || {});
    fetching.value = false;

    return supportedIds.value;
  };

  const updateSupportedIdVerification = async (form: SupportedIdVerification[]) => {
    if (updating.value) return;

    updating.value = true;
    const { data, error } = await supportedIdVerificationService.updateSupportedIdVerification(form);
    updating.value = false;

    if (error.value) {
      showSnackbar({
        text: error.value,
        state: false,
      });
    } else {
      return data.value.data;
    }
  };

  const parseSupportedIdObject = (obj: Record<string, string>): void => {
    const collection = [];

    for (let key in obj) {
      const value = obj[key];
      if (value) {
        collection.push({
          id: key,
          name: value,
        });
      }
    }

    return collection;
  };

  return {
    supportedIds,
    fetching,
    updating,

    getSupportedIdVerifications,
    updateSupportedIdVerification,
  };
}
