<script setup lang="ts">
import { AppSelectField } from '@/components';

const props = withDefaults(
  defineProps<{
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    loading: false,
    disabled: false,
  }
);

const value = defineModel();
const { supportedIds, fetching, getSupportedIdVerifications } = useSupportedIdVerification();

const isLoading = computed((): boolean => {
  return props.loading || fetching.value;
});

onMounted(() => {
  nextTick(async () => {
    await getSupportedIdVerifications();
  });
});
</script>
<template>
  <div class="select-id-verification">
    <AppSelectField v-model="value" :items="supportedIds" item-value="id" item-title="name" placeholder="Select ID" />
  </div>
</template>
<style lang="scss" scoped>
.select-id-verification {
  .v-card {
    border-radius: 16px;

    label {
      cursor: pointer !important;
    }
  }

  &__item,
  &__loader {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__item {
    &__content {
      max-width: 90%;
    }
  }
}
</style>
