class SupportedIdVerificationService {
  async getSupportedIdVerifications() {
    const { data, error, execute } = useSubmit();
    await execute('frankieone/supported-id-verifications', {
      method: 'GET',
    });

    return {
      data: {
        value: data.value?.data || {},
      },
      error,
    };
  }

  async updateSupportedIdVerification(form: { via: string }) {
    const { data, error, execute } = useSubmit();
    await execute(`frankieone/supported-id-verifications`, {
      method: 'POST',
      body: form,
    });

    return {
      data,
      error,
    };
  }
}

const service = new SupportedIdVerificationService();
export default service;
