<script setup lang="ts">
import loudspeaker from '@/assets/img/loudspeaker.png';
import { AppSelectIdVerification } from '@/components/fields';
import { AppButton, AppFormCard, AppFormHeader, AppTypography } from '@/core/components';
import type { SupportedIds } from '@/core/types/SupportedIdVerification';
import { useAuthStore } from '@/modules/auth/store/auth';

const props = withDefaults(
  defineProps<{
    title: string;

    isOnboarding: boolean;
  }>(),
  {
    isOnboarding: true,
  }
);

const route = useRoute();
const { user } = useAuthStore();
const { VALIDATIONS } = useValidation();
const {
  v$,
  form,
  handleSubmit,
  getErrors: getValidationError,
} = useForm<{ via: SupportedIds }>({
  form: {
    via: null,
  },
  rules: {
    via: {
      required: VALIDATIONS.required('ID'),
    },
  },
});
const { updating, updateSupportedIdVerification } = useSupportedIdVerification();

const errors = computed(() => {
  return getValidationError();
});

const canSubmit = computed((): boolean => {
  return !v$.value.$invalid;
});

const { resetOptions } = useAuthStore();
const save = async (): Promise<void> => {
  await handleSubmit(async function () {
    const response = await updateSupportedIdVerification(form.value);
    if (response) {
      user.fo_verification = response.fo_verification;

      resetOptions();
      if (props.isOnboarding) {
        await navigateTo({ name: 'onboarding', query: { form: 'personal-profile' } });
      } else {
        await reloadNuxtApp();
      }
    }
  });

  if (errors.value.services) {
    showSnackbar({
      text: errors.value.services,
      state: 'warning',
    });
  }
};

const initialize = (): void => {
  form.value.via = user.fo_verification;
};

onMounted(() => {
  initialize();
});
</script>

<template>
  <div class="id-selector">
    <AppFormHeader v-if="!isOnboarding" :title="title" :show-button="false" class="mb-5" />

    <AppFormCard :title="!isOnboarding ? title : ''" class="mx-auto disclaimer-card">
      <template v-if="isOnboarding">
        <AppTypography type="large" element="h1" class="text-center">Before you start...</AppTypography>
        <v-img :src="loudspeaker" class="mx-auto" width="124" height="160" />
      </template>
      <template v-else> </template>
      <AppTypography type="body-2" element="div" class="text-justify">
        Select which ID you'll be using to verify your identity.
      </AppTypography>
      <!--       <AppTypography type="body-2" element="div" class="text-justify">
        NOTE: Proof of Age card cannot be used if you are based in ACT, WA, or TAS.
      </AppTypography> -->

      <AppSelectIdVerification v-model="form.via" :error-messages="errors.via" class="pt-6" />

      <AppButton
        v-if="isOnboarding"
        :disabled="!canSubmit || updating"
        :loading="updating"
        block
        color="primary"
        class="mt-4"
        rounded="xl"
        type="button"
        @click="save"
      >
        Continue
      </AppButton>
    </AppFormCard>

    <AppButton
      v-if="!isOnboarding"
      :disabled="!canSubmit || updating"
      :loading="updating"
      block
      color="primary"
      class="mt-4"
      rounded="xl"
      type="button"
      @click="save"
    >
      Continue
    </AppButton>
  </div>
</template>

<style scoped>
.id-selector {
  width: 100%;
  max-width: var(--form-max-width);
  margin: 0 auto;
}
</style>
